<template>
    <div class="page">
        <!-- <div class="tblup">
            <div class="glotbl spcwrp">
                <div class="tbTit">
                    信息填写
                </div>
                <div class="tbInnr">
                    <label for="">学生姓名</label>
                    <input type="text" placeholder="请填写学生的姓名" v-model="name">
                </div>
                <div class="tbInnr">
                    <label>身份证号</label>
                    <input type="idcard" placeholder="请填写学生身份证号码" v-model="certno">
                </div>
                <div class="tbInnr">
                    <label for="">手机</label>
                    <input type="text" placeholder="请填写手机号码" v-model="phone">
                </div>

            </div>
        </div>
        <div class="tblup">
            <div class="glotbl">
                <div class="tbInnr tbCount">
                    <div></div>
                    <div>
                        <text class="ans">合计 </text>
                        <text>¥{{ total }}</text>
                    </div>

                </div>
            </div>
        </div>
        <div class="prf">
            <p>如有咨询，请联系以下电话： 057755583353</p>
            <p><br />产品规格: 200元每人/年</p>

            <div>
                <br />适合人群<br />
                凡在学校注册，身体健康，能正常学习和生活的大学均可作为被保险人参加本保险。<br />
                <br />保障范围<br />
                意外身故: 20万元<br />
                意外伤残: 10万元<br />
                疾病身故: 10万元<br />
                疾病全残: 10万元<br />
                意外门 (急) 诊: 10万元<br />
                住院医疗: 100万元<br />
                特定疾病门诊医疗: 100万元<br />
                <br />特别约定<br />
                <p>
                    
                    1、意外身故保险金额20万元和意外残疾按残疾等级保险金，最高给付10万元。<br />
                    2、疾病身故或疾病全残等待期0天。<br />
                    3、非特定意外门（急）诊医疗保险金额为10万元，次免赔额0元，给付比例100%，将社保、农保定点医疗机构纳入意外门（急）诊医疗理赔范围，其中：（1）一般意外门（急）诊医疗保险金额为8万元，含因意外遭受动物伤害引起的疫苗费（含进口等丙类药品）给付责任，且单次事故疫苗费赔付金额以2000元为限；（2）承担因意外伤害引起的创伤性牙齿修复（含种植牙、镶牙）及微创美容缝合给付责任，共用保险金额2万元，且每颗牙齿赔付金额以5000元为限。<br />

                    4、住院医疗和特定疾病门诊医疗次免赔额0元，给付比例100%，无疾病观察期。<br />
                    5、被保险人参加公费医疗、基本医疗保险。<br />
                </p>

                <br />保障时间<br />
                2023年9月1日-2024年8月31日<br />
                法定受益人<br />
                本产品受益人:法定受益人
            </div>

        </div>

        <div class="Btmwrp">
            <label>¥{{ total }}</label>
            <a herf="javascript:void(0);" @click="sendOrder">去支付</a>
        </div> -->
    </div>
</template>

<script>


export default {
    data() {
        return {
            sended: true,
            domin: this.domin,
            imagesign: "",
            ordersid: 0,
            name: "",
            certno: "",
            Product_Merchant: 0,
            phone: "",
            item: {},
            mid: 0,
            total: 200,
        }
    },
    created() {
        // this.getDetails()
        document.title = "登记"
        location.href="https://wxmsb.cpic.com.cn/fmsb/newZXStore/page/selectProductPage.html?zxId=10045672&empNo=HAZED586&scenariosRadio=1&noticesText=%E5%95%86%E5%AD%A6%E9%99%A2&isOnline=1"

    },
    mounted() {
        document.title = "登记"

    },
    methods: {
        getDetails() {
            // 获取订单详情
            this.spost({
                url: '/Ajax/WordWelfare/getstocklist',
                data: {
                    id: 219
                },
                succ: res => { }
            })
        },
        sendOrder() {
            if (this.name == '') {
                alert('请填写学生姓名')

                return false
            }

            if (this.certno == '' || this.certno.length != 18) {
                alert('请填写学生的身份证号')
                return false
            }
            if (this.phone == '' || this.phone.length != 11) {
                alert('请填写手机号码')
                return false
            }

            if (this.sended == false) {
                return false
            }

            // 防止多次点击支付下单按钮
            this.sended = false


            this.wpost({
                url: '/Ajax/WordWelfare/createordersdirectbuy',
                data: {
                    stockid: 1911,
                    certno: this.certno,
                    count: 1,
                    name: this.name,
                    phone: this.phone,
                },
                fail: (res) => {
                    this.sended = true
                    alert('投保未成功，请稍后再试！')
                },
                succ: (data) => {
                    this.sended = true
                    console.log("订单:", data)
                    this.ordersid = data.ordersid

                    // 发起支付

                    this.wpost({
                        url: '/Ajax/WordWelfare/getwxpaydata',
                        data: {
                            ordersid: this.ordersid,
                            contactname: this.name,
                            contactphone: this.phone,
                        },
                        succ: (data) => {

                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                "appId": data.wxdata.appId, //公众号名称，由商户传入     
                                "timeStamp": data.wxdata.timeStamp, //时间戳，自1970年以来的秒数     
                                "nonceStr": data.wxdata.nonceStr, //随机串     
                                "package": data.wxdata.package,
                                "signType": data.wxdata.signType, //微信签名方式：     
                                "paySign": data.wxdata.paySign //微信签名 
                            },
                                (res) => {
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        alert('支付成功!')
                                        this.$router.push('/orderSuccess')
                                    } else {
                                        alert('支付失败!')
                                    }
                                });

                        }
                    })

                }
            })

        },

    }
}
</script>

<style>
.page {
    min-height: 80vh;
}

body {
    background: none;
}

.prf {
    padding: 16px;
    line-height: 2.1;
}

.active-tips {
    padding: 12px;
    margin: 12px 0;
}

.glotbl {
    background-color: #fff;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp img {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.swichwrp switch {
    scale: 0.8;
}

.swichwrp {
    padding: 5px 0;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #ccc;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #005bac;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 17px;
    line-height: 3.1;
    box-sizing: border-box;
}

picker {
    width: 100%;
    font-size: 15px;
    padding-left: 1rem;
    height: 43px;
    box-sizing: border-box;
}

picker .uni-input {
    height: 43px;
    line-height: 43px;
}

.uni-input {
    height: 25px;
    line-height: 25px;
}

.tbInnr textarea {
    width: 100%;
    margin: 8px 0;
    border-radius: 5px;
    padding: 6px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.7;
}

.adrsWrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
}

.adrsWrp text {
    width: 31%;
}

.tb-mod {
    white-space: normal;
    font-size: 12px;
    margin-top: 5px;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tb-mintit {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;

}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
}

.tbftp {
    align-items: flex-start;
}

.Btmwrp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 89;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #333;

}

.Btmwrp label {
    margin-left: 12px;
    font-size: 30px;
}

.Btmwrp a {
    color: #fff;
    display: inline-block;
    background-color: #005bac;
    margin: 0;
    height: 47px;
    line-height: 47px;
    padding: 0 22px;
    border-radius: 22px;
    text-align: center;
    font-size: 15px;
}
</style>
